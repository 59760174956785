


































import { Page, Sort, Filter, Edit, Toolbar, Resize, ColumnChooser, ExcelExport, Grid } from "@syncfusion/ej2-vue-grids";
import { Component, Vue, Ref } from 'vue-property-decorator'
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";;
//import { ButtonPlugin } from "@syncfusion/ej2-vue-buttons";
import { MyGridOption } from '@/Helper/MyDataAdapter';
import { ClassType, Enums, UserType } from "@/Helper/Enum";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { DataManager } from "@syncfusion/ej2-data";
import { createElement } from "@syncfusion/ej2-base";
Vue.use(GridPlugin);
Vue.use(DateRangePickerPlugin);
//Vue.use(ButtonPlugin);
let ClassTypeInstance: any = null;
let UserTypeInstance: any = null;
@Component({
    provide: {
        grid: [Page, Sort, Filter, Edit, Toolbar, Resize, ColumnChooser, ExcelExport]
    }


})
export default class LiveClass extends Vue {
    data: any = {};
    @Ref() readonly grid!: any
    @Ref() readonly date!: any
    ClassTypeFilter = {
        ui: {
            create: function (args: any) {
                let flValInput = createElement('input', { className: 'flm-input' });
                args.target.appendChild(flValInput);
                ClassTypeInstance = new DropDownList({
                    dataSource: new DataManager(Enums.ClassType),
                    fields: { text: 'data', value: 'value' },
                    placeholder: 'Select a value',
                    popupHeight: '200px'
                });
                ClassTypeInstance.appendTo(flValInput);
            },
            write: function (args: any) {
                ClassTypeInstance.value = args.filteredValue;
            },
            read: function (args: any) {
                args.fltrObj.filterByColumn(args.column.field, args.operator, ClassTypeInstance.value);
            }
        }
    }
    UserTypeFilter = {
        ui: {
            create: function (args: any) {
                let flValInput = createElement('input', { className: 'flm-input' });
                args.target.appendChild(flValInput);
                UserTypeInstance = new DropDownList({
                    dataSource: new DataManager(Enums.UserType),
                    fields: { text: 'data', value: 'value' },
                    placeholder: 'Select a value',
                    popupHeight: '200px'
                });
                UserTypeInstance.appendTo(flValInput);
            },
            write: function (args: any) {
                UserTypeInstance.value = args.filteredValue;
            },
            read: function (args: any) {
                args.fltrObj.filterByColumn(args.column.field, args.operator, UserTypeInstance.value);
            }
        }
    }
    //DemoStatusFilter = {
    //    ui: {
    //        create: function (args: any) {
    //            let flValInput = createElement('input', { className: 'flm-input' });
    //            args.target.appendChild(flValInput);
    //            UserTypeInstance = new DropDownList({
    //                dataSource: new DataManager(Enums.DemoStatusType),
    //                fields: { text: 'data', value: 'value' },
    //                placeholder: 'Select a value',
    //                popupHeight: '200px'
    //            });
    //            UserTypeInstance.appendTo(flValInput);
    //        },
    //        write: function (args: any) {
    //            UserTypeInstance.value = args.filteredValue;
    //        },
    //        read: function (args: any) {
    //            args.fltrObj.filterByColumn(args.column.field, args.operator, UserTypeInstance.value);
    //        }
    //    }
    //}
    dataManager = MyGridOption.getDataManager('SalesZoomFeedbackList');
    filterOptions = { type: 'Menu' };
    wrapSettings = { wrapMode: 'Both ' };

    toolbarOptions = ['ColumnChooser', 'ExcelExport'];
    formatOptions = { type: 'date', format: 'MM-dd-yyyy' };
   

    ClassType(field: any, data: any, column: any) {
        return ClassType[data[field]];
    }
    UserType(field: any, data: any, column: any) {
        return UserType[data[field]];
    }

    toolbarClick(args: any) {
        if (args.item.id === 'Grid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name

            let excelExportProperties = {
                //  fileName: 'SalesZoomFeedback.xlsx'
            };
            this.grid.excelExport(excelExportProperties);
        }
    }
    change(args: any) {
        debugger;

        var dateValue1 = new Date(this.date.ej2Instances.value[0]).toDateString();
        var dataValue = new Date(this.date.ej2Instances.value[1]).toDateString(); //get date values from date range picker
        var date = new Date(this.date.ej2Instances.value[1]).getDate();
        var incdate = date + 1;
        var dateValue2 = dataValue.replace(date.toString(),incdate.toString());
        this.grid.ej2Instances.filterSettings.columns = [
            {
                value: dateValue1,
                operator: "greaterthanorequal",
                field: "Start",
                predicate: "and"
            },
            {
                value: dateValue2,
                operator: "lessthanorequal",
                field: "Start",
                predicate: "and"
            }
        ];
    }

   
}





